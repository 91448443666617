import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { featureColors } from "utils"

const ProductivityVisibility = ({
  hat,
  title,
  subtitle,
  media,
  // backgroundImage,
}) => {
  // const renderBackground = () => (
  //   <BackgroundImage>
  //     <Media media={backgroundImage} />
  //   </BackgroundImage>
  // )

  return (
    <StyledProductivityVisibility>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        // custom={renderBackground}
        mobileFull
      />
    </StyledProductivityVisibility>
  )
}

const StyledProductivityVisibility = styled.div`
  /* --tg-max-width: 600px; */
  --tg-hat-color: ${featureColors.productivity};
`

export default ProductivityVisibility
