import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { device, featureColors } from "utils"

const ProductivityInventoryControl = ({
  hat,
  title,
  subtitle,
  media,
  // backgroundImage,
}) => {
  // const renderBackground = () => (
  //   <BackgroundImage>
  //     <Media media={backgroundImage} />
  //   </BackgroundImage>
  // )

  return (
    <StyledProductivityInventoryControl>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        align="left"
        mobileTgOrder="1"
        // custom={renderBackground}
      />
    </StyledProductivityInventoryControl>
  )
}

const StyledProductivityInventoryControl = styled.div`
  --tg-max-width: 665px;
  --tg-hat-color: ${featureColors.productivity};
  --section-max-width: 1080px;

  .imageWrap {
    @media ${device.laptop} {
      margin-left: -2%;
      margin-top: var(--sp-24);
    }
  }
`

export default ProductivityInventoryControl
