import React from "react"
import styled from "styled-components"

import { HSection, Grid, ValueProp } from "components/common"

import { device, featureColors } from "utils"

const ProductivityLabTesting = ({
  hat,
  title,
  subtitle,
  valueProps,
  media,
}) => {
  const sectionMinHeight = media.desktop.file.details.image.height

  const renderGrid = () => (
    <Grid
      width="100%"
      list={valueProps}
      component={(vp) => <ValueProp orientation="horizontal" {...vp} />}
    />
  )

  return (
    <StyledProductivityLabTesting sectionMinHeight={sectionMinHeight}>
      <HSection
        hat={hat}
        title={title}
        titleAs="h2"
        subtitle={subtitle}
        media={media}
        grid={renderGrid}
        mobileFull
      />
    </StyledProductivityLabTesting>
  )
}

const StyledProductivityLabTesting = styled.div`
  --tg-hat-color: ${featureColors.productivity};
  --section-max-width: 1080px;

  @media ${device.laptop} {
    /* --tg-max-width: 270px; */
  }

  .gridWrap {
    .vp_contentWrap {
      grid-template-columns: 22px auto;
      grid-gap: var(--sp-24);
      --tg-max-width: 300px;

      .image {
        padding-top: 5px;
      }

      .subtitle {
        font-weight: normal;
        line-height: var(--sp-32);
      }
    }
  }
`

export default ProductivityLabTesting
