import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta, featureGlass, device } from "utils"

import {
  ProductivityHero,
  ProductivityAutomation,
  ProductivityInventoryControl,
  ProductivityQuaratine,
  ProductivityVisibility,
  ProductivityOrders,
  ProductivityReporting,
  ProductivityTeamGoals,
  ProductivityLabTesting,
} from "src/sections/features/productivity"

import ExploreFeatures from "src/sections/features/ExploreFeatures"

import { CtaGetDemo } from "components/cta"

const ProductivityPage = ({ data: { productivity } }) => {
  const {
    productivity_hero,
    productivity_automation,
    productivity_inventory_control,
    productivity_quarantine,
    productivity_visibility,
    productivity_orders,
    productivity_reporting,
    productivity_team_goals,
    productivity_lab_testing,
    explore_more_features,
    cta_get_demo,
  } = useContentfulSection(productivity.sections)

  const meta = useMeta(productivity)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <ProductivityHero {...productivity_hero} />
        <ProductivityAutomation {...productivity_automation} />
        <ProductivityInventoryControl {...productivity_inventory_control} />
        <ProductivityQuaratine {...productivity_quarantine} />
        <ProductivityVisibility {...productivity_visibility} />
        <ProductivityOrders {...productivity_orders} />
        <ProductivityReporting {...productivity_reporting} />
        <ProductivityTeamGoals {...productivity_team_goals} />
        <ProductivityLabTesting {...productivity_lab_testing} />
        <ExploreFeatures {...explore_more_features} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  .explore_features {
    ${featureGlass};

    margin-bottom: var(--sp-mobile-section);
    @media ${device.laptop} {
      margin-bottom: var(--sp-section);
    }
  }
`

export const query = graphql`
  {
    productivity: contentfulPage(pageId: { eq: "productivity" }) {
      ...Page
    }
  }
`

export default ProductivityPage
