import React from "react"
import styled from "styled-components"

import { HSection, Grid, ValueProp } from "components/common"

import { device, featureColors, featureGlass } from "utils"

const ProductivityTeamGoals = ({ hat, title, subtitle, valueProps, media }) => {
  const sectionMinHeight = media.desktop.file.details.image.height

  const renderGrid = () => (
    <Grid
      width="100%"
      list={valueProps}
      component={(vp) => <ValueProp orientation="horizontal" {...vp} />}
    />
  )

  return (
    <StyledProductivityTeamGoals sectionMinHeight={sectionMinHeight}>
      <HSection
        hat={hat}
        title={title}
        titleAs="h2"
        subtitle={subtitle}
        media={media}
        grid={renderGrid}
        mobileFull
      />
    </StyledProductivityTeamGoals>
  )
}

const StyledProductivityTeamGoals = styled.div`
  --tg-hat-color: ${featureColors.productivity};
  --section-padding-top: 164px;
  --section-max-width: 1080px;
  ${featureGlass};

  @media ${device.laptop} {
    --tg-max-width: 370px;
  }

  .gridWrap {
    .vp_contentWrap {
      grid-template-columns: 22px auto;
      grid-gap: var(--sp-24);
      --tg-max-width: 300px;

      .image {
        padding-top: 5px;
      }
      .subtitle {
        font-weight: normal;
        line-height: var(--sp-32);
      }
    }
  }
`

export default ProductivityTeamGoals
