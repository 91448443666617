import React from "react"
import styled from "styled-components"

import {
  device,
  useContentfulComponent,
  featureColors,
  featureGlass,
} from "utils"

import {
  VerticalSection,
  ValuePropSplit,
  ValueProp,
  Grid,
} from "components/common"

const ProductivityAutomation = ({
  hat,
  title,
  subtitle,
  valueProps,
  media,
  components,
}) => {
  const { productivity_automation_image_vps } = useContentfulComponent(
    components
  )

  const renderMedia = () => (
    <ValuePropSplit
      media={media}
      valueProps={productivity_automation_image_vps.valueProps}
    />
  )

  const renderGrid = () => (
    <Grid
      className="bottomGrid"
      col={3}
      gap={48}
      align="center"
      width="225px"
      list={valueProps}
      component={(vp) => <ValueProp align="center" {...vp} />}
    />
  )

  return (
    <StyledProductivityAutomation>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        // media={media}
        custom={renderMedia}
        mobileTgOrder="1"
        // gridOrder="3"
        customOrder="2"
        mobileCustomOrder="2"
        grid={renderGrid}
      />
    </StyledProductivityAutomation>
  )
}

const StyledProductivityAutomation = styled.div`
  --tg-hat-color: ${featureColors.productivity};

  ${featureGlass};

  @media ${device.laptop} {
    --tg-max-width: 570px;
  }

  .bottomGrid {
    .vp_contentWrap {
      .subtitle {
        font-weight: normal;
        line-height: var(--sp-32);
      }
    }
  }
`

export default ProductivityAutomation
